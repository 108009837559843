<template>
  <div v-if="show" @click.self="$emit('close')" class=" anime transform top-0 left-0 w-full h-full fixed"
      :class="show ? 'translate-x-0' : '-translate-x-full'">
      <div class="container mx-auto bg-white rounded w-10/12 sm:w-6/12 mt-12">
          <span class="flex justify-end p-3 pb-1"><Iconclose @click="$emit('close')"/></span>
          <div class="pt-0 p-6">
              <div class="">
                  <span class="text-lg md:text-xl lg:text-2xl font-semibold" style="color: #201F1E;">{{ $t("findreload.infotitle") }}</span>
              </div>
            <div class="flex justify-center mt-6">
              <img src="@/assets/images/findinfo.webp" />
            </div>
          </div>
          
      </div>
  </div>
</template>

<script>
import Iconclose from '../../assets/svg/dashbIcons/close.svg?inline';
export default {
    components:{
        Iconclose,
    },
    props: {
      show: {
        type: Boolean,
        default: false
      }
    },
    watch: {
    show: {
      immediate: true,
      handler(show) {
       if (process.client) {
          if (show) document.body.style.setProperty("overflow", "hidden");
          else document.body.style.removeProperty("overflow");
        }
      }
    }
    }
}
</script>

<style scoped>
.anime{
    animation: modal 400ms ease-out forwards;
}
@keyframes modal {
    0% {
        background-color: rgba(0, 0, 0, 0);
    }
    25% {
        background-color: rgba(0, 0, 0, 0.15);
    }
    50% {
        background-color: rgba(0, 0, 0, 0.3);
    }
    75% {
        background-color: rgba(0, 0, 0, 0.5);
    }
    100% {
        background-color: rgba(0, 0, 0, 0.75);
    }
}
</style>