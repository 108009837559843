<template>
<div>
  <div class="sm:p-10 px-5 py-10">
    <div class="sm:text-2xl text-base sm:font-semibold flex flex-row justify-center items-center">
        <span class="mr-4"><Iconcompt2/></span> 
        <span class="" style="color:#222F5A">{{ $t("findreload.title") }}</span>
    </div>
    <div class="flex justify-center my-8">
      <img src="@/assets/svg/findmeter.svg" />
    </div>
    <div>
        <form @submit.prevent="onFindReload">
            <div class="mb-4" v-if="notfound">
              <div class="bg-tex-16 flex justify-center rounded p-2">
                <span class="text-sm text-compteur-50">{{$t("findreload.notfoundError")}} </span>
              </div>
            </div>
            <div class="mb-4">
                <label class="text-sm" for="compId">{{ $t("findreload.inputMeter.label") }}</label>
                <br>
                <input  placeholder="Ex: 24563368425"
                    v-model="counterNum"
                    class="p-3 h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus:border-kinput-2 rounded w-full leading-tight focus:outline-none focus:bg-white" 
                    id="compId" 
                    name="compId"
                    @input="resetValidation"
                    oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    type="number"
                    maxlength="20"
                    v-validate="'required|numeric'"
                    :class="{ ' border-tex-2': submitted && errors.has('compId') }">

                <p v-show="submitted && errors.has('compId')" class="text-tex-2">{{ $t("findreload.inputMeter.required") }}</p>
                <p v-show="notFoundMeter" class="text-tex-2 text-sm">{{ $t("findreload.meterNotFound") }}</p>
            </div>
              <div>
                  <label class="text-sm flex" for="compId">
                    <span>{{ $t("findreload.inputId.label") }}</span>
                    <span class="cursor-pointer" @click="toggleInfo()">
                      <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.96501 7.45035C9.35161 7.45035 9.66501 7.13695 9.66501 6.75035C9.66501 6.36375 9.35161 6.05035 8.96501 6.05035C8.57842 6.05035 8.26501 6.36375 8.26501 6.75035C8.26501 7.13695 8.57842 7.45035 8.96501 7.45035Z" fill="black"/>
                        <path d="M10.5 12.3004H9.5V8.30035H8C7.86739 8.30035 7.74021 8.35303 7.64645 8.4468C7.55268 8.54057 7.5 8.66775 7.5 8.80035C7.5 8.93296 7.55268 9.06014 7.64645 9.15391C7.74021 9.24768 7.86739 9.30035 8 9.30035H8.5V12.3004H7.5C7.36739 12.3004 7.24021 12.353 7.14645 12.4468C7.05268 12.5406 7 12.6677 7 12.8004C7 12.933 7.05268 13.0601 7.14645 13.1539C7.24021 13.2477 7.36739 13.3004 7.5 13.3004H10.5C10.6326 13.3004 10.7598 13.2477 10.8536 13.1539C10.9473 13.0601 11 12.933 11 12.8004C11 12.6677 10.9473 12.5406 10.8536 12.4468C10.7598 12.353 10.6326 12.3004 10.5 12.3004Z" fill="black"/>
                        <path d="M9 3.80035C7.81331 3.80035 6.65328 4.15225 5.66658 4.81154C4.67989 5.47082 3.91085 6.4079 3.45673 7.50425C3.0026 8.60061 2.88378 9.80701 3.11529 10.9709C3.3468 12.1348 3.91825 13.2039 4.75736 14.043C5.59648 14.8821 6.66558 15.4536 7.82946 15.6851C8.99335 15.9166 10.1997 15.7978 11.2961 15.3436C12.3925 14.8895 13.3295 14.1205 13.9888 13.1338C14.6481 12.1471 15 10.987 15 9.80035C15 8.20905 14.3679 6.68293 13.2426 5.55771C12.1174 4.4325 10.5913 3.80035 9 3.80035ZM9 14.8004C8.0111 14.8004 7.0444 14.5071 6.22215 13.9577C5.39991 13.4083 4.75904 12.6274 4.38061 11.7138C4.00217 10.8001 3.90315 9.79481 4.09608 8.8249C4.289 7.855 4.76521 6.96408 5.46447 6.26482C6.16373 5.56556 7.05465 5.08935 8.02455 4.89643C8.99446 4.7035 9.99979 4.80252 10.9134 5.18096C11.8271 5.55939 12.6079 6.20026 13.1574 7.0225C13.7068 7.84475 14 8.81145 14 9.80035C14 10.457 13.8707 11.1071 13.6194 11.7138C13.3681 12.3204 12.9998 12.8716 12.5355 13.3359C12.0712 13.8002 11.52 14.1685 10.9134 14.4198C10.3068 14.671 9.65661 14.8004 9 14.8004Z" fill="black"/>
                      </svg>
                    </span>
                  </label>
                <br>
                <input placeholder="Ex: 05Rp3QZCJ"
                    v-model="kkiapayId"
                    class="p-3 h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus-within:border-kinput-2 rounded w-full leading-tight focus:outline-none focus:bg-white" 
                    id="transactionId" 
                    name="transactionId"
                    @input="resetValidation"
                    type="text"
                    maxlength="20"
                    v-validate="'required'"
                    :class="{ 'border border-tex-2': submitted && errors.has('transactionId') }">
                <p v-show="submitted && errors.has('transactionId')" class="text-tex-2">{{ $t("findreload.inputId.required") }}</p>
                <p v-show="errorMessage" class="text-tex-2">{{ $t("findreload.inputId.error") }}</p>
            </div>
            <br>
            <base-button color="secondary" :applyDisabledColor="!loading" full type="submit" :text="$t('findreload.askbtn')" :loading="loading" :disabled=" !(gteZero && hasMeterNumber)" />
        </form>
    </div>
  </div>
  <find-info-modal :show="showInfo" @close="showInfo = false" />
</div>
</template>

<script>
import Iconcompt2 from '../../assets/svg/compt2.svg?inline';
// import IconFindMeter from '../../assets/svg/findmeter.svg?inline';
import BaseButton from '../BaseButton.vue';
import FindInfoModal from './FindInfoModal.vue';
export default {
  components: {
    Iconcompt2,
    // IconFindMeter,
    BaseButton,
    FindInfoModal
  },

  data(){
    return {
      kkiapayId: '',
      counterNum: '',
      submitted: false,
      loading: false,
      errorMessage: false,
      notFoundMeter: false,
      notfound: false,
      showInfo: false
    }
  },
  computed: {
    gteZero(){
      return this.kkiapayId.length > 0
    },
    hasMeterNumber(){
      return this.counterNum.length > 0
    },
    bill() {
      return {
        amount: 1300,
        code: "5278 6342 5396 4193 2112",
        createdAt: "2021-06-09T10:33:40.944Z",
        debt: "0.00",
        fees: "0",
        kwh: "8.50",
        meterId: "60915c8b9c263c08fceaffc6",
        meterNumb: "22140056791",
        meterOwner: "AGBO JOSEPHINE",
        paymentMode: "MOBILE_MONEY",
        refCode: "PCS010232",
        sbeeInvoice: "0000000818",
        total: "1325",
        transacFees: "25",
        transacId: "60c098d2d792a23a38d24396",
        tva: "191.25",
        updatedAt: "2021-06-09T10:33:40.944Z",
        userId: null,
        userPhone: "229 66587101",
        __v: 0,
        _id: "60c09904d792a23a38d24397",
      }
    }
  },

  methods: {
    onFindReload() {
      this.loading = true;
      this.$store.dispatch('findReload', {kkiapayId: this.kkiapayId, meterNum: this.counterNum})
      .then(response => {
       console.log('response ----> ', response);
        const purchaseData = {
          counterNum: response.meterNum,
          amount: response.amount,
          codeSts: response.code,
          kwh: response.kwh,
          entretien: response.fees,
          dettes: response.debt,
          tva: response.tva,
          fraisMtn: response.transacFees,
          total: response.total,
          id: response.transacId
        }
        this.$emit('findsucces', purchaseData)
      })
      .catch(error => {
        if (error.data.meter) {
          this.notFoundMeter = true
        }
        this.$emit('finderror', error)
        this.notfound = true
      })
      .finally(() => {
        this.loading = false
      })
    },

    resetValidation() {
      if (this.notFoundMeter) {
        this.notFoundMeter = false
      }
      if (this.notfound) {
        this.notfound = false
      }
      
    },
    toggleInfo(){
      this.showInfo = ! this.showInfo
    }
  },

  // mounted(){
  //   const response = this.bill
  //   const purchaseData = {
  //     counterNum: response.meterNum,
  //     amount: response.amount,
  //     codeSts: response.code,
  //     kwh: response.kwh,
  //     entretien: response.fees,
  //     dettes: response.debt,
  //     tva: response.tva,
  //     fraisMtn: response.transacFees,
  //     total: response.total,
  //     id: response.transacId
  //   }
  //   purchaseData
  //   this.$emit('findsucces', purchaseData)
  // }
}
</script>

<style>
.my-8 {
  margin-top: 2rem/* 32px */;
  margin-bottom: 2rem/* 32px */;
}
.items-center {
  align-items: center;
}
</style>