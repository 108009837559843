<template>
  <div>
    <toast-message :show="toast.show" @close="toast.show = false" :color="toast.color" :message="toast.message" style="top: 16px; right: 16px;">
        <span slot="icon">
        <component :is="toast.icon" />
      </span>
    </toast-message>
    <div class="p-3">
      <div class="mb-6">
        <div class="text-center">
            <div class="flex justify-center">
            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle opacity="0.2" cx="40" cy="40" r="40" fill="#27AE60"/>
                <circle cx="39.9999" cy="40" r="32.4211" fill="#27AE60"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M58.372 25.6797C59.072 26.2923 59.143 27.3563 58.5304 28.0563L34.9515 55.0037C34.6445 55.3545 34.2059 55.5624 33.7401 55.5779C33.2742 55.5934 32.8227 55.4151 32.4931 55.0855L22.3878 44.9803C21.7301 44.3225 21.7301 43.2561 22.3878 42.5984C23.0456 41.9407 24.1119 41.9407 24.7697 42.5984L33.602 51.4307L55.9955 25.8382C56.608 25.1382 57.672 25.0672 58.372 25.6797Z" fill="white" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            </div>
          <span class="text-sm sm:text-lg">{{$t("findreload.foundtext")}}</span>
        </div>
      </div>
      <div class="container col-span-1 lg:col-span-3 border border-kborder-10 rounded shadow">
          <div class=" flex justify-center items-center">          
              <div class="p-4 flex flex-col">
                  <div class="flex justify-center">
                      <span class="lg:hidden font-bold flex mb-5">
                          <span class="flex flex-col justify-center font-bold items-center">
                              <span class="flex items-center">
                                  <IconStsmb class />
                                  <strong class="ml-2">{{ $t("rechargeSuccess.sts") }} : </strong>
                              </span>
                              <span v-if="paySuccessData.amount != 0" class="sm:text-lg" style="color:red" @dblclick="copyText('sts')">{{ paySuccessData.codeSts }}</span>
                              <span v-else style="color:red" class="text-sm">{{ $t("rechargeSuccess.noCode") }}</span>
                              <button v-if="paySuccessData.amount != 0 && webShareApiSupported()"  @click="shareCode" class="px-2 mt-2 focus:outline-none border border-kborder-7 bg-compteur-500 rounded ">
                                  <span class="flex items-center"><span class="text-kborder-7 mr-1 text-sm">{{ $t("rechargeSuccess.shareText") }}</span> <span><Iconshare /></span></span> 
                              </button>
                              <button v-if="paySuccessData.amount != 0 && !webShareApiSupported()"  @click="copyText('sts')" class="px-2 mt-2 focus:outline-none border border-kborder-7 bg-compteur-500 rounded ">
                                  <span class="flex items-center"><span class="text-kborder-7 mr-1 text-sm">{{ $t("rechargeSuccess.copyText") }}</span> <span><Iconcopy /></span></span> 
                              </button>
                          </span>
                      </span>

                      <div class="hidden lg:inline text-2xl ml-2 font-bold mb-5">
                          <div class="flex justify-center">
                            <span>
                                <IconSts class="hidden lg:inline" />
                            </span>
                            <strong class="font-bold ml-2">{{ $t("rechargeSuccess.sts") }} : </strong>
                          </div>
                          <div class="flex justify-center">
                            <span v-if="paySuccessData.amount != 0" style="color:red;" id="sts" @dblclick="copyText('sts')">{{ paySuccessData.codeSts }}</span>
                            <span v-else style="color:red" class="sm:text-xl">{{ $t("rechargeSuccess.noCode") }}</span>
                          </div>
                          <div class="flex justify-center">
                            <button v-if="paySuccessData.amount != 0"  @click="copyText('sts')" class="px-2 ml-5 focus:outline-none border border-kborder-7 bg-compteur-500 rounded ">
                                <span class="flex items-center"><span class="text-kborder-7 mr-1 text-base">{{ $t("rechargeSuccess.copyText") }}</span> <span><Iconcopy /></span></span> 
                            </button>
                          </div>
                      </div>
                  </div>
                  <div class="grid lg:grid-flow-row lg:grid-rows-3 lg:grid-cols-2 gap-y-3 gap-x-0 mb-6">
                      <div class="flex flex-row">
                          <Iconflash />
                          <span class="ml-2">
                              <strong class="font-bold">Kwh: </strong>
                              {{ paySuccessData.kwh }} Kwh
                          </span>
                      </div>
                      <div class="flex flex-row">
                          <Icontool />
                          <span class="ml-2">
                              <strong class="font-bold">{{ $t("rechargeSuccess.entretien") }}: </strong>
                              {{ paySuccessData.entretien }} FCFA
                          </span>
                      </div>
                      <div class="flex flex-row">
                          <Icondette />
                          <span class="ml-2">
                              <strong class="font-bold">{{ $t("rechargeSuccess.dettes") }}: </strong>
                              {{ paySuccessData.dettes }} FCFA
                          </span>
                      </div>

                      <div class="flex flex-row">
                          <Iconmomo />
                          <span class="ml-2">
                              <strong class="font-bold">{{ $t("rechargeSuccess.frais") }}:</strong>
                              {{ paySuccessData.fraisMtn }} FCFA
                          </span>
                      </div>
                      <div class="flex flex-row">
                          <Iconcash />
                          <span class="ml-2">
                              <strong class="font-bold">Total:</strong>
                              {{ paySuccessData.total }} FCFA
                          </span>
                      </div>
                      <div class="flex flex-row">
                          <span>
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="12" cy="12" r="12" fill="#E6ECF4"/>
                                  <path d="M14.395 6.01812C13.7869 5.36156 12.9375 5 12 5C11.0575 5 10.2053 5.35937 9.60003 6.01187C8.98815 6.67156 8.69003 7.56812 8.76003 8.53625C8.89878 10.4462 10.3522 12 12 12C13.6478 12 15.0988 10.4466 15.2397 8.53687C15.3107 7.5775 15.0107 6.68281 14.395 6.01812Z" fill="#F11C33"/>
                                  <path d="M17.5002 19H6.50018C6.3562 19.0019 6.21361 18.9716 6.08279 18.9115C5.95197 18.8513 5.83621 18.7627 5.74393 18.6522C5.5408 18.4094 5.45893 18.0778 5.51955 17.7425C5.7833 16.2794 6.60643 15.0503 7.90018 14.1875C9.04955 13.4216 10.5055 13 12.0002 13C13.4949 13 14.9508 13.4219 16.1002 14.1875C17.3939 15.05 18.2171 16.2791 18.4808 17.7422C18.5414 18.0775 18.4596 18.4091 18.2564 18.6519C18.1642 18.7625 18.0484 18.8511 17.9176 18.9113C17.7868 18.9715 17.6442 19.0018 17.5002 19Z" fill="#222F5A"/>
                              </svg>
                          </span>
                          <span class="ml-2">
                              <strong class="font-bold">Tva: </strong>
                              <span class="">{{ paySuccessData.tva }} FCFA</span>
                          </span>
                      </div>
                  </div>
                  <base-button :loading="loading" :applyDisabledColor="false" :disabled="loading" @click="downloadBill" color="secondary" :text="$t('findreload.downloadtext')" />
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '../BaseButton.vue'
import Iconcash from "../../assets/svg/icons/cash.svg?inline";
import IconSts from "../../assets/svg/icons/codeSTS.svg?inline";
import IconStsmb from "../../assets/svg/icons/codestsmb.svg?inline";
import Icondette from "../../assets/svg/icons/dette.svg?inline";
import Iconflash from "../../assets/svg/icons/flash.svg?inline";
import Iconmomo from "../../assets/svg/icons/MTNmomo.svg?inline";
import Iconcopy from "../../assets/svg/icons/copy.svg?inline";
import Iconshare from "../../assets/svg/icons/share.svg?inline";
import IconSuccess from "../../assets/svg/icons/toascheck.svg?inline";
import IconError from "../../assets/svg/icons/toasterror.svg?inline";
// import Iconarrow from "../../assets/svg/icons/redarrow.svg?inline";
import Icontool from "../../assets/svg/icons/tool.svg?inline";
import ToastMessage from '../ToastMessage.vue';
export default {
  components: {
    BaseButton,
    // Iconarrow,
    Iconshare,
    Iconcopy,
    IconSts,
    IconStsmb,
    Iconflash,
    Icontool,
    Icondette,
    Iconmomo,
    Iconcash,
    ToastMessage,
    IconSuccess,
    IconError

  },
  props: {
    paySuccessData: {
      type: Object,
      default(){
        return {}
      }
    }
  },

  data(){
    return {
      copyCode: false,
      loading: false,
      toast: {
          show: false,
          message: "",
          icon: 'IconSuccess',
          color: 'bg-bgk-3'
      }
    }
  },

  methods: {
    copyText(id) {
        var r = document.createRange();
        r.selectNode(document.getElementById(id));
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(r);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
        this.copyCode = !this.copyCode;
        const toast = {
            show: true,
            message: this.$t("rechargeSuccess.copyStsMessage"),
            icon: 'IconSuccess',
            color: 'bg-bgk-3'
        }
            this.toast = toast
    },

    async shareCode(){
        const shareData = {
        title: 'Code de recharge KKiaPaySBEE',
        text: this.paySuccessData.codeSts
        }
        try {
            await navigator.share(shareData)
        } catch(err) {
            console.log(err)
        }
    },

    webShareApiSupported() {
      console.log('navigator share --->', navigator.share);
        return navigator.share
    },
    downloadBill() {
        const transacId = this.paySuccessData.id;
        this.loading = true
        this.$store.dispatch('downloadBillRequest', transacId)
        .then(data => {
            window.open(data, '_blank');
        })
        .catch(() => {
            const toast = {
                show: true,
                message: this.$t("rechargeSuccess.downloadErrorMessage"),
                icon: 'IconError',
                color: 'bg-bgk-6'
            }
            this.toast = toast
        })
        .finally(() => {
            this.loading = false
        })
    }
  }
}
</script>

<style>
@media (min-width: 1024px) {
  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 1024px) {
  .lg\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }
}
</style>