<template>
  <div>
    <component :is="comp.name" v-bind="comp.props" v-on="comp.event" ></component>
  </div>
</template>

<script>
import FindReloadForm from './FindReloadForm.vue'
import FindReloadSuccess from './FindReloadSuccess.vue';

export default {
  components: { FindReloadForm, FindReloadSuccess },
  data(){
    return {
      comp: {
        name: "find-reload-form",
        event: {
          findsucces: this.onFindReloadSuccess,
        }
      },
    }
  },

  methods: {
    onFindReloadSuccess(e) {
      // console.log('onFindReloadSuccess event ---->', e);
      this.comp = {
        name: 'FindReloadSuccess',
        props: {
          paySuccessData: e
        },
        event: {}
      }
    }
  },

}
</script>

<style>

</style>