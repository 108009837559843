<template>
  <div class="w-full">
    <div class="max-w-lpw mx-auto">
      <div class="flex justify-between px-0 py-4 mx-auto csm:w-11/12 clg:w-83p">
        <router-link to="/">
          <Iconkkiapay class="csm:hidden" />
          <Iconkkiapaysm class="clg:hidden" />
        </router-link>
        <div class="flex flex-nowrap items-center">
          <router-link to="/recharges/register">
            <lButton :text="$t('sign.register')" />
          </router-link>
          <router-link class="ml-4 csm:ml-2" to="/recharges/signsuccess">
            <hButton :text="$t('sign.login')" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Iconkkiapay from "components/landingPage/Iconkkiapay.vue";
import Iconkkiapaysm from "components/landingPage/Iconkkiapaysm.vue";
import hButton from "components/landingPage/hButton.vue";
import lButton from "components/landingPage/lButton.vue";

export default {
  components: {
    Iconkkiapay,
    Iconkkiapaysm,
    hButton,
    lButton,
  },

  data() {
    return {
      signMenu: false,
    };
  },
};
</script>

<style scoped>
</style>
