<template>
  <div class="relative">
    <div
      class="1"
      style="
        height: 100vh;

        background-image: url('/landingPage/assets/Group.svg');
      "
    >
      <header class="">
        <Khead />
      </header>
      <div class="flex justify-center">
        <div class="shadow-xl bg-white rounded px-10 mt-2">
          <ManageFindReload />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ManageFindReload from "../../components/findReloadComponents/ManageFindReload.vue";
import Khead from "components/landingPage/partials/KheadTransparent.vue";
export default {
  components: {
    ManageFindReload,

    Khead,
  },
  data() {
    return {
      modalContentIndex: 0,
      openModal: false,
      closed: true,
      signMenu: false,
    };
  },
  computed: {
    findReloadStep() {
      return this.$store.getters.getFindReloadStep;
    },
    paymentStep() {
      return this.$store.getters.getPaymentStep;
    },
    pleaseWaitStep() {
      return this.$store.getters.getPleaseWaitStep;
    },
  },
  mounted() {
    this.$store.commit("mutPaymentStep", false);
    this.$store.commit("mutPleaseWaitStep", false);
    this.$store.commit("mutFindReloadStep", false);
  },
  methods: {
    showFindReload() {
      this.$store.commit("mutFindReloadStep", true);
    },
    readContent() {
      return this.modalContents[this.modalContentIndex];
    },

    closeModal() {
      this.openModal = false;
    },
    closeBlue() {
      console.log(this.closed, "nnnnnnnnnnnn-----");
      return (
        (this.closed = false), console.log(this.closed, "nnnnnnnnnnnn-----")
      );
    },
  },
};
</script>

<style scoped>
body {
  background-color: red;
}
.bg-frame-916 {
  width: 117.5px;
  height: 113.75px;
  top: -2.5rem;
  right: -2.281rem;
}

.modal {
  width: 100%;
  justify-content: center;
  height: 100vh;
  z-index: 30;
  background-color: rgba(0, 0, 0, 0.665);
  padding: 80px;
}
.sbeeicon {
  width: 2.477rem;
  height: 2.477rem;
}
.message {
  display: flex;
  gap: 10px;
  color: #1e3b71;
  background-color: #eef2fa;
}

@media (max-width: 767px) {
  .modal {
    height: 100vh;
    justify-content: center;
  }
  .sbeeicon {
    width: 1.773rem;
    height: 1.773rem;
  }
}
</style>
